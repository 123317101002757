export default function useAppRegistrationCRUD() {
  const keyId = useId()

  const { $portalApi } = useNuxtApp()
  const { t } = useI18n()

  const appRegistrationError = useState<Record<string, any> | null>(`app-registration-error-${keyId}`, () => null)
  const appUnregisterError = useState<Record<string, any> | null>(`app-unregister-error-${keyId}`,() => null)

  // uses as a state to pass data from spec renderer to app creation/registration flow
  const apiData = useState<PortalApiResponseTemp<'fetch-api'> | null>(() => null)

  /**
   * Registers API product to application
   * @param {string} applicationId - Application ID to make a registration.
   * @param {string} apiId - API ID to make a registration.
   * @returns {Promise<void>}
   */
  const registerApiToApplication = async (applicationId: string, apiId: string): Promise<void> => {
    try {
      appRegistrationError.value = null

      if (applicationId && apiId) {
        await $portalApi('/api/v3/applications/{applicationId}/registrations', {
          method: 'POST',
          path: {
            applicationId,
          },
          body: {
            api_id: apiId,
          },
        })
      } else {
        appRegistrationError.value = createError({ data: { detail: t('errors.general.param_should_not_be_empty', { param: 'applicationId or productVersionId' }) } })
      }
    } catch (e: any) {
      appRegistrationError.value = e
    }
  }

  const registrationApiId = useState<string>(`app-registration-api-id-${keyId}`,() => '')

  /**
   * Unregister API product version from application
   * @param {string} applicationId - Application ID to unregister.
   * @param {string} registrationId - Registration ID to unregister.
   * @returns {Promise<void>}
   */
  const unregisterApiFromApplication = async (applicationId: string, registrationId: string): Promise<void> => {
    try {
      appUnregisterError.value = null

      if (applicationId && registrationId) {
        await $portalApi('/api/v3/applications/{applicationId}/registrations/{registrationId}', {
          method: 'DELETE',
          path: {
            applicationId,
            registrationId,
          },
        })
      } else {
        appUnregisterError.value = createError({ data: { detail: t('errors.general.param_should_not_be_empty', { param: 'applicationId or registrationId' }) } })
      }
    } catch (e: any) {
      appUnregisterError.value = e
    }
  }

  return {
    appRegistrationError,
    registerApiToApplication,
    unregisterApiFromApplication,
    appUnregisterError,
    apiData,
    registrationApiId,
  }
}
